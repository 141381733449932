<template>
  <div
    style="
      border-radius: 5px;
      margin: 10px 50px;
      padding: 30px;
      background-color: #fff;
      box-shadow: 0 0 10px rgba(200, 200, 200, 0.5);
      min-height: 100vh;
    "
  >
    <el-button
      @click="routeBack"
      type="success"
      style="display: inline-block; float: right"
      >Назад</el-button
    >
    <keep-alive include="@/components/ecologist/dataEntry/dataEntryMain,dataEntryMainView">
      <slot></slot>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "containerComponent",
  methods: {
    routeBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped></style>
